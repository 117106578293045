/* styles.css */
.icon-button {
    cursor: pointer;
    width: 308px;
    height: 36px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 9px;
    border: 0;
    border-radius: 6px;
    background-color: transparent;
    color: #000000;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    outline: none;
    transition: background-color 0.3s;
}

.icon-button:hover {
    background-color: #2E303D;
    color: #fff;
}

.icon-button.selected {
    background-color: #2E303D;
    border: 1px solid #2E303D;
    color: #ffffff;
}


/* .icon-button.selected:hover {
    background-color: #3A3D47;
    color: #ffffff;
} */

.icon-button .icon {
    font-size: 24px;
    color: #21263c;
}