.otherDetails {
    margin-bottom: 20px;
    padding-left: 15px;
}

.allExtras {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-right: 20px;
    justify-content: space-between;
}