.details-container {
    padding-left: 20px;
    padding-right: 15px;
    margin-bottom: 10px;
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    fill: #e3162a;
}

/* .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgb(255, 0, 0);
    font-size: 18px;
    border: none;
    padding: 5px;
    border-radius: 50%;

} */

.user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
}

.user-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.user-details {
    display: flex;
    flex-direction: column;
}

.user-name {
    color: #030303;
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 19px;
}

.user-account {
    color: #030303;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 17px;
}

.additional-info {
    margin-top: 10px;
}

.user-id,
.org-id {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
}

.allbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}