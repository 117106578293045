/* Center the table horizontally */
.table-container {
    padding: 20px;
}

.table_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-container h1 {
    text-align: center;
}

/* Style for the table */
table {
    width: 80%;
    /* Adjust table width */
    border-collapse: collapse;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Soft shadow for the table */
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
    /* White background for the table */
}

/* Header Row Styling */
th {
    background-color: #232340;
    /* Dark blue */
    color: #ffffff;
    /* White text color */
    padding: 16px;
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
}

/* Table Row Styling */
td {
    background-color: #f2f2f2;
    /* Light grey for rows */
    color: #333333;
    /* Dark text for readability */
    padding: 12px 16px;
    text-align: left;
    font-size: 1rem;
    border-bottom: 1px solid #ddd;
    /* Light border for row separation */
}

/* Hover Effect for Rows */
tr:hover td {
    background-color: #e6e6e6;
    /* Slightly darker grey on hover */
    cursor: pointer;
}

/* Remove the bottom border for the last row */
tr:last-child td {
    border-bottom: none;
}

/* Title Styling */
h1 {
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: #232340;
    /* Dark blue title color */
    margin-bottom: 20px;
}