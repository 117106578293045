.header {
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 9vh;
    background-color: #232340;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 16px;
}