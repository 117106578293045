.mainContainer {
    position: fixed;
    top: 9vh;
    left: 6vw;
    /* background-color: antiquewhite; */
    height: 91vh;
    width: 94vw;
    overflow-y: scroll;
}

.mainContainer::-webkit-scrollbar {
    display: none;
}