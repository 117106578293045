/* General Page Styling */
body {
    font-family: "Arial", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
}

.signup-container {
    max-width: 450px;
    /* Limits width for better alignment */
    margin: 40px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 20px;
    /* Adds inner padding to avoid content spilling out */
    box-sizing: border-box;
    /* Ensures padding is included in width */
}

/* Header Section */
.signup-header {
    background-color: #4b96ff;
    color: white;
    padding: 20px;
    text-align: center;
}

.signup-header h1 {
    margin: 0;
    font-size: 22px;
}


/* Form Wrapper */
.signup-form-wrapper {
    margin: 0 auto;
}

.signup-form {
    margin-top: 20px;
}

.signup-form h2 {
    text-align: center;
    color: #333;
    font-size: 20px;
}

.signup-form p {
    text-align: center;
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

/* Input Fields */
.form-input {
    width: 100%;
    /* Ensures input spans the full width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 14px;
    box-sizing: border-box;
    /* Includes padding in width calculation */
}

.form-input:focus {
    border-color: #4b96ff;
    outline: none;
    box-shadow: 0px 0px 5px rgba(75, 150, 255, 0.5);
}

/* Terms and Conditions */
.terms {
    font-size: 12px;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
}

/* Buttons */
label {
    display: block;
    /* Ensures labels stack properly above inputs */
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

/* Buttons */
.btn {
    width: 48%;
    padding: 10px;
    margin: 0 1%;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease;
}

.signup-btn {
    background-color: #4b96ff;
    color: white;
}

.signup-btn:hover {
    background-color: #3579d7;
}

.cancel-btn {
    background-color: #ddd;
    color: #333;
}

.cancel-btn:hover {
    background-color: #bbb;
}

/* Success Message */
.success-message {
    text-align: center;
    font-size: 14px;
    color: #4caf50;
    margin-top: 20px;
}