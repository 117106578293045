.drawer_container {
    position: fixed;
    top: 9vh;
    left: 6vw;
    height: 91vh;
    width: 20vw;
    min-width: 300px;
    background-color: #ffffff;
    padding: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 10;
    color: #ddddddd0;
}



.heading {
    margin-bottom: 25px;
    color: #6e1cf4;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 500;
    line-height: 17px;
    text-transform: uppercase;
}